import PropTypes from 'prop-types';
import React from 'react';

import Footer from './Footer';

function Layout({ children }) {
    return (
        <div className="subpixel-antialiased flex flex-col min-h-screen font-sans text-gray-200">
            <main className="flex-1 w-full">{children}</main>

            <Footer></Footer>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
