import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

function Footer() {
    const footerImage = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "logo_dark.png" }) {
                childImageSharp {
                    fluid(maxHeight: 200) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <footer className="bg-black-500">
            <div className="container px-5 pt-2 pb-4 mx-auto flex items-center sm:flex-row flex-col">
                <a
                    className="flex title-font font-medium items-center md:justify-start justify-center text-white"
                    href="/"
                >
                    <Img
                        fluid={footerImage.file.childImageSharp.fluid}
                        alt="Logo"
                        className="w-20"
                    />
                    <span className="sr-only">Godfather's Car Wash</span>
                </a>
                <p className="text-black-300 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-primary-400 sm:py-2 sm:mt-0 mt-4">
                    © 2020 Godfather's Car Wash —
                    <OutboundLink
                        href="https://github.com/thecodingfamilyes"
                        className="text-black-200 ml-1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @thecodingfamily
                    </OutboundLink>
                </p>
                <span className="inline-flex space-x-5 sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                    <OutboundLink
                        className="text-black-300 hover:text-facebook-500"
                        href="https://www.facebook.com/godfatherscarwash/"
                        target="_blank"
                        rel="noopener"
                    >
                        <svg
                            fill="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-8 h-8"
                            viewBox="0 0 24 24"
                        >
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                        </svg>
                        <span className="sr-only">facebook</span>
                    </OutboundLink>

                    <OutboundLink
                        className="ml-3 text-black-300 hover:text-instagram-500"
                        href="https://www.instagram.com/godfatherscarwash/"
                        target="_blank"
                        rel="noopener"
                    >
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-8 h-8"
                            viewBox="0 0 24 24"
                        >
                            <rect
                                width="20"
                                height="20"
                                x="2"
                                y="2"
                                rx="5"
                                ry="5"
                            ></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                        <span className="sr-only">instagram</span>
                    </OutboundLink>
                </span>
            </div>
        </footer>
    );
}

export default Footer;
