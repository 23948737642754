import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { GatsbySeo, LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, keywords, title }) {
    const { site } = useStaticQuery(graphql`
        query DefaultSEOQuery {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;

    const jsonLD = {
        name: "Godfather's Car Wash",
        type: 'AutoWash',
        images: ['https://godfatherscw.com/logo_v2.png'],
        id: 'https://godfatherscw.com',
        url: 'https://godfatherscw.com',
        telephone: '+34630608688',
        priceRange: '8-210',
        address: {
            streetAddress:
                'San Aquilino, 2, Parking público Intercambiador Plaza Castilla, Sótano 2',
            addressLocality: 'Madrid',
            postalCode: '28029',
            addressCountry: 'ES',
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: 40.4675451,
            longitude: -3.6903695,
        },
        openingHoursSpecification: {
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            opens: '09:00',
            closes: '20:30',
        },
    };

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                script={[
                    {
                        type: 'text/javascript',
                        innerHTML: `var cpm = {};
(function(h,u,b){
var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
e.async=true;e.src='https://cookiehub.net/c2/4edaf681.js';
e.onload=function(){u.cookiehub.load(b);}
d.parentNode.insertBefore(e,d);
})(document,window,cpm);`,
                    },
                ]}
            ></Helmet>
            <GatsbySeo
                title={title}
                description={metaDescription}
                canonical="https://godfatherscw.com/"
                openGraph={{
                    url: 'https://godfatherscw.com/',
                    title,
                    description: metaDescription,
                    images: [
                        {
                            url: 'https://godfatherscw.com/logo_v2.png',
                            width: 800,
                            height: 800,
                            alt: 'Anagrama',
                        },
                    ],
                    site_name: "Godfather's Car wash",
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <LocalBusinessJsonLd {...jsonLD} />
        </>
    );
}

SEO.defaultProps = {
    lang: `es`,
    keywords: [],
    meta: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    lang: PropTypes.string,
    meta: PropTypes.array,
    title: PropTypes.string.isRequired,
};

export default SEO;
